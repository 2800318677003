/**
 * Navbar
 */

//  import PerfectScrollbar from 'perfect-scrollbar';
//  import 'perfect-scrollbar/css/perfect-scrollbar.css';
// import hoverIntent from "jquery-hoverintent";

export function navbar () {
  // $('.navbar').addClass('tween');

  // Show menu overlay when click the navbar toggler
  const navbarToggler = document.querySelectorAll('.js-navbar-toggler');
  // menu button
  if (navbarToggler) {
    navbarToggler.forEach(function(item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        if($('body').hasClass('navbar--opened')){
          $('body').removeClass('navbar--opened');
        } else {
          $('body').addClass('navbar--opened');
        }
      });
    });
    
  }

  const navbarElement = document.querySelector('.js-navbar')
  const navbarShowHidetPixels = navbarElement.clientHeight;
  let scrollPos = 0;
  var eventAlertPixels = 0;

  function navbarShowHide () {
    var scrolled = document.scrollingElement.scrollTop;
    var position = navbarElement.offsetTop;
    
    if(typeof(document.querySelector(".event-alert")) != 'undefined' && document.querySelector(".event-alert") != null){
      eventAlertPixels = document.querySelector('.event-alert').clientHeight;
    }
    
    if (scrolled > (position + navbarShowHidetPixels + eventAlertPixels)) {
      let windowY = window.scrollY;

      navbarElement.classList.add('out-stage');
      
      if (windowY < scrollPos) {
        // Scrolling UP
        navbarElement.classList.add('on-stage');
        // console.log('S. UP')
      } else {
        // Scrolling DOWN
        navbarElement.classList.remove('on-stage');
        // resetSubmenus();
        // console.log('S. DOWN')
      }
      scrollPos = windowY
    } else {
      navbarElement.classList.remove('on-stage', 'out-stage');
    }
  }

  // Navbar slides out of view when scrolling down and slides back in when scrolling up
  // Based on https://dev.to/changoman/showhide-element-on-scroll-w-vanilla-js-3odm
  function navbarScrollCheck () {
    if (navbarElement) {
      if (navbarElement.classList.contains('navbar--fixed-showhide') && !$('body').hasClass('navbar--opened')) {
        navbarAddScrollListener()
      }
    }
  }
  function navbarAddScrollListener() {
    window.addEventListener('scroll', navbarShowHide)
  }
  navbarScrollCheck();

  // Submenu on preview Menu
  // const btnHasSubmenu = document.querySelectorAll('.has-submenu');

  // var resetSubmenus = function(){
  //   document.querySelectorAll('.submenu.show').forEach(function(submenu){
  //     submenu.classList.remove('show');
  //   })
  // }
  
  // btnHasSubmenu.forEach(function(btn){
  //   var next = btn.nextElementSibling;
  //   while (next) {
  //     if (next.classList.contains('submenu')) {
  //       btn.addEventListener('click', function (e) {
  //         e.preventDefault();
  //         e.stopPropagation();
  //         if (!next.classList.contains('show')) {
  //           resetSubmenus();
  //           next.classList.add('show');
  //         } else {
  //           next.classList.remove('show');
  //         }
  //       })
  //       break;
  //     }
  //   }
  // })

  // $('.has-submenu').parent().hoverIntent({
  //   over: function(){
  //     $(this).find('.submenu').addClass('show');
  //   },
  //   out: function(){
  //     $(this).find('.submenu').removeClass('show');
  //   },
  //   sensitivity: 8
  // });


  // $(document).on('mousemove', function(event) {
  //   var threshold =  navbarElement.clientHeight * 1.5;
  //   if(event.clientY > threshold) {
  //     navbarElement.classList.remove('expose_nav');
  //   } else {
  //     if(!navbarElement.classList.contains('out-stage')){
  //       navbarElement.classList.add('expose_nav');
  //     }
  //   }
  // });
}

export default navbar
