/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-global-assign */
/**
 * @title App
 * @description Application entry point
  */

// Jquery
window.jQuery = $;
window.$ = $;
jQuery = $;

// Barba - pages transition
import barba from "@barba/core";
import barbaCss from "@barba/css";

import Flickity from "flickity";
import "flickity/css/flickity.css";
require("flickity-fade");
require('flickity-imagesloaded');

// Modal
import modal from "jquery-modal";

// Lazyload image
import LazyLoad from "vanilla-lazyload";

// Components
import spinner from "./components/spinner";

var imagesLoaded = require("imagesloaded");

import SimpleBar from 'simplebar';
import { Loader } from '@googlemaps/js-api-loader';

var bodyScrollLock = require('body-scroll-lock');
var disableBodyScroll = bodyScrollLock.disableBodyScroll;
var enableBodyScroll = bodyScrollLock.enableBodyScroll;

// Modules
import navbar from "../modules/navbar/navbar";
import thomDropdown from "../modules/dropdown/dropdown";
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import thomForm from "./components/thom_form";

// exist element
$.fn.exists = function () {
  return this.length !== 0;
};

gsap.config({
  nullTargetWarn: false
});

// Modal default options
$.modal.defaults = {
  clickClose: true,
  escapeClose: true,
  fadeDuration: 250,
  fadeDelay: 1.1,
  showClose: false,
  spinnerHtml: '<div id="spinner" class="default"><div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div></div>',
  blockerClass: "jquery-modal"
}

const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "mobile";
  }
  return "desktop";
};

var myLazyLoad;
let timelineInterval;

// Form Response Action
function MyThomFormResponseAction(title, text) {
  var mytitle;
  if (title !== undefined && title !== null && title !== "") {
    mytitle = "<div class='title md'><strong>" + title + "</strong></div>";
  } else {
    mytitle = "";
  }

  var myHtml =
    '<div class="modal modal-callback">' +
      '<a href="#close" rel="modal:close" class="close"></a>' +
      mytitle +
      "<div class='paragraph md'>" + text + "</div>"; +
    '</div>';

  $(myHtml).appendTo("body").modal({
    blockerClass: "dialog"
  });
}

let grecaptchaBadgeShow = () => {
  $(".grecaptcha-badge").removeClass('hide');
}
let grecaptchaBadgeHide = () => {
  $(".grecaptcha-badge").addClass('hide');
}

// Start APP
function myapp() {

  $('img')
    .on('dragstart', function(event) { 
      event.preventDefault(); 
    })
    .on('contextmenu', function(e) {
      return false;
    });
  
  myLazyLoad = null;
  myLazyLoad = new LazyLoad({
    unobserve_entered: true,
    elements_selector: ".lazy",
  });

  $('.has-submenu').on({
    "mouseenter" : function(e){
      var _delay = ($(this).hasClass("has-submenu")) ? 200 : 0 ;
      $(this).find(".submenu").stop(true);
      $(this).find(".submenu").delay(_delay).addClass('show');
    },
    "mouseleave" : function(e){
      $(this).find(".submenu").stop(true);
      $(this).find(".submenu").delay(50).removeClass('show');
    },
  })

  // Dropdown è resa globale
  window.thomDropdown = thomDropdown;

  window.thomDropdown();

  if($('.main-form').length > 0){
    ScrollTrigger.create({
      trigger: '.main-form',
      start: "top 100%",
      end: "bottom 0",
      // once: true,
      // markers: true,
      onEnter: grecaptchaBadgeShow,
      onEnterBack: grecaptchaBadgeShow,
      onLeave: grecaptchaBadgeHide,
      onLeaveBack: grecaptchaBadgeHide
    });
  }

  if($('.event-alert').length > 0){
    $('.event-alert .close-alert').on('click', function(e){
      e.preventDefault();
      $('.event-alert').slideUp();
    })
  }
  
  // Home Main Slider 
  if(typeof(document.querySelector(".main-slider")) != 'undefined' && document.querySelector(".main-slider") != null){
    const mainSlider = document.querySelector(".main-slider .slideshow");
    let autoplayTime = 7000;
    if(mainSlider.getAttribute('data-autoplay')){
      autoplayTime = Number(mainSlider.getAttribute('data-autoplay'))
    }
    
    var mainSliderFlkty = new Flickity( mainSlider, {
      autoPlay: autoplayTime,
      cellSelector: '.item',
      draggable: false,
      fade: true,
      prevNextButtons: false,
      pageDots: true,
      lazyLoad: true,
      on: {
        ready: function() {
          if($('.main-slider .flickity-page-dots .dot').length > 1){
            $('.main-slider .flickity-page-dots').show();
          }
        }
      }
    });

    // mainSliderFlkty.on('pointerUp', function (event, pointer) {
    //   console.log(event)
    //   mainSliderFlkty.player.play();
    // });

    var mainSliderTl = gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector(".main-slider"),
        start: "top 80%",
        end: "bottom 15%",
        // markers: true,
        onEnter: function(){
          mainSliderStart($(".main-slider .slideshow .item.is-selected"));
        },
        onEnterBack: function(){
          mainSliderStart($(".main-slider .slideshow .item.is-selected"));
        },
        onLeave: function(){
          $(".main-slider .slideshow .item").each(function(){
            if($(this).find(".video").length > 0){
              var videoElement = $(this).find(".video")[0];
              videoStop($(this), videoElement);
            }
          });
        },
        onLeaveBack: function(){
          $(".main-slider .slideshow .item").each(function(){
            if($(this).find(".video").length > 0){
              var videoElement = $(this).find(".video")[0];
              videoStop($(this), videoElement);
            }
          });
        },
      }
    });

    mainSliderFlkty.on('change', function(index) {
      mainSliderFlkty.player.play();
      $(".main-slider .slideshow .item").each(function(){
        if($(this).hasClass('is-selected')){
          mainSliderStart($(this));
        } else {
          if($(this).find(".video").length > 0){
            var videoElement = $(this).find(".video")[0];
            videoStop($(this), videoElement);
          }
        }
      });
    });

    var videoPlay = function(item, itemVideo){
      item.addClass('is-playing');
      itemVideo.play();
      item.find('.state').removeClass('stop');
    }

    var videoStop = function(item, itemVideo){
      item.removeClass('is-playing');
      itemVideo.pause();
    }

    var mainSliderStart = function(item){
      var $this = item;
      if($this.find(".video").length > 0){
        var videoElement = $this.find(".video")[0];
        
        if(!$this.hasClass('videoloaded')){
          let videoSrc = $this.find(".video").data('src');
          videoElement.src = videoSrc;
          videoElement.load();
          videoElement.addEventListener('canplay', (event) => {
            $this.addClass('videoloaded');
            videoPlay($this, videoElement);
          });
          videoElement.addEventListener('ended', (event) => {
            mainSliderFlkty.next(true, false);
          });

          if($this.find('.video--controls').length > 0){
            var controls = $this.find('.video--controls');
            var restart = controls.find('.skip-start');
            var volume = controls.find('.volume');
            var state = controls.find('.state');
            var progress = controls.find('.progress-bar');
    
            if(window.matchMedia("(min-width: 920px)").matches){
              setTimeout(function(){
                controls.fadeOut();
              }, 2000);
    
              $this.hover(function(){
                controls.fadeIn();
              }, function(){
                controls.fadeOut();
              })
            }
    
            restart.on('click', function(){
              videoElement.pause();
              videoElement.currentTime = '0';
              videoElement.play();
              state.removeClass('stop');
            });
    
            volume.on('click', function(){
              $(this).toggleClass('on');
              if($(this).hasClass('on')){
                videoElement.muted = false;
              } else {
                videoElement.muted = true;
              }
            });
    
            state.on('click', function(){
              $(this).toggleClass('stop');
              if($(this).hasClass('stop')){
                videoElement.pause();
              } else {
                videoElement.play();
              }
            });
          }
        }else{
          videoPlay($this, videoElement);
        }
      }
    }
  }

  // Carousel
  if(typeof(document.querySelector(".carousel")) != 'undefined' && document.querySelector(".carousel") != null){
    const carousel = document.querySelector(".carousel");
    
    var carouselFlkty = new Flickity( carousel, {
      autoPlay: 0,
      groupCells: true,
      // cellAlign: 'left',
      contain: true,
      cellSelector: '.item',
      prevNextButtons: false,
      pageDots: true,
      lazyLoad: true,
      on: {
        ready: function() {
          if($('.carousel .flickity-page-dots .dot').length > 1){
            $('.carousel .flickity-page-dots').show();
          }

          $('.item').each(function(){
            var $this = $(this);

            if($this.find('.item--media')){
              var $thisMedia = $(this);
              if($this.find(".video--item").length > 0){
                var videoElement = $this.find(".video--item")[0];
                let videoSrc = $this.find(".video--item").data('src');
        
                videoElement.src = videoSrc;
                videoElement.load();

                $this.on({
                  "mouseenter" : function(e){
                    var _delay = ($this.find(".video--item")) ? 200 : 0 ;
                    $this.find('.item--media').addClass('is-playing');
                    videoElement.play();
                  },
                  "mouseleave" : function(e){
                    $this.find('.item--media').removeClass('is-playing');
                    videoElement.pause();
                  },
                })
              }

              
            }
          })
        }
      }
    });

  }

  // Fade in animation
  const animFadeInEl = gsap.utils.toArray('.anim__fadeIn');
  // Set things up
  gsap.set(animFadeInEl, {autoAlpha: 0, yPercent: 5});
  animFadeInEl.forEach((el, i) => {
    // Set up your animation
    const animFadeInAnim = gsap.to(el, {duration: 1, autoAlpha: 1, yPercent: 0, paused: true});
    // Use callbacks to control the state of the animation
    ScrollTrigger.create({
      trigger: el,
      start: "top 90%",
      end: "bottom 90%",
      once: true,
      // markers: true,
      onEnter: self => {
        // If it's scrolled past, set the state
        // If it's scrolled to, play it
        self.progress === 1 ? animFadeInAnim.progress(1) : animFadeInAnim.play()
      }
    });
  });

  // Scroll Up in animation
  const animScrollUpEl = gsap.utils.toArray('.anim__scrollUp');
  ScrollTrigger.matchMedia({"(min-width:640px)": function() {
      animScrollUpEl.forEach((el, i) => {
        let animScrollUpTl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            end: "bottom 8%",
            scrub: 0.5,
            // markers: true
          }
        });
        animScrollUpTl.to(el, {
          yPercent: -5,
          ease: "power1.inOut"
        })
      });
    }
  })

  // Scroll Down in animation
  const animScrollDownEl = gsap.utils.toArray('.anim__scrollDown');
  ScrollTrigger.matchMedia({"(min-width:640px)": function() {
      animScrollDownEl.forEach((el, i) => {
        let animScrollDownTl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            end: "bottom 8%",
            scrub: 1,
            // markers: true
          }
        });
        animScrollDownTl.to(el, {
          yPercent: 5,
          ease: "power1.inOut"
        })
      });
    }
  })

  // Image ZoomIn animation
  const animZoomInEl = gsap.utils.toArray('.anim__zoomIn');
  animZoomInEl.forEach((el, i) => {
    let animZoomInTl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: "center bottom",
        end: "center top",
        scrub: 1,
        // markers: true
      }
    });
    animZoomInTl.to(el, {
      scale: 1.1,
      ease: "power1.inOut"
    })
  });

  // Image ZoomOut animation
  const animZoomOutEl = gsap.utils.toArray('.anim__zoomOut');
  gsap.set(animZoomOutEl, {scale: 1.1});
  animZoomOutEl.forEach((el, i) => {
    let animZoomOutTl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: "center bottom",
        end: "center top",
        scrub: 1,
        // markers: true
      }
    });
    animZoomOutTl.to(el, {
      scale: 1,
      ease: "power1.inOut"
    })
  });

  // video media / Scrolltrigger
  if($('.media').exists()){
    $('.media').each(function(){
      var $this = $(this);
      if($this.find(".video--item").length > 0){
        var videoElement = $this.find(".video--item")[0];
        let videoSrc = $this.find(".video--item").data('src');

        videoElement.src = videoSrc;
        videoElement.load();



        if($this.find('.video--controls').length > 0){
          var controls = $this.find('.video--controls');
          var restart = controls.find('.skip-start');
          var volume = controls.find('.volume');
          var state = controls.find('.state');
          var progress = controls.find('.progress-bar');
  
          if(window.matchMedia("(min-width: 920px)").matches){
            setTimeout(function(){
              controls.fadeOut();
            }, 2000);
  
            $this.hover(function(){
              controls.fadeIn();
            }, function(){
              controls.fadeOut();
            })
          }
  
          restart.on('click', function(){
            videoElement.pause();
            videoElement.currentTime = '0';
            videoElement.play();
            state.removeClass('stop');
          });
  
          volume.on('click', function(){
            $(this).toggleClass('on');
            if($(this).hasClass('on')){
              videoElement.muted = false;
            } else {
              videoElement.muted = true;
            }
          });
  
          state.on('click', function(){
            $(this).toggleClass('stop');
            if($(this).hasClass('stop')){
              videoElement.pause();
            } else {
              videoElement.play();
            }
          });
        }
        
        let itemOnEnter = () => {
          $this.addClass('is-playing');
          videoElement.play();
        }
        let itemOnLeave = () => {
          $this.removeClass('is-playing');
          videoElement.pause();
        }

        var mediaTl = gsap.timeline({
          scrollTrigger: {
            trigger: $this[0],
            start: "top 80%",
            end: "bottom 10%",
            // markers: true,
            onEnter: itemOnEnter,
            onEnterBack: itemOnEnter,
            onLeave: itemOnLeave,
            onLeaveBack: itemOnLeave,
          }
        });
      }
    })
  }

  if($('[data-type="tab"]').exists()){
    var tabs = $('[data-type="tab"]');
    
    tabs.each(function(){
      var $this = $(this);
      var tabNavItem = $this.find('[data-tab]');
      var tabNavContent = $this.find('[data-tab-content]');
      
      tabNavItem.each(function(){
        if($(this).hasClass('selected')){
          var tabContent = $(this).data('tab');
          $this.find('[data-tab-content = '+  tabContent + ']').slideDown();
        }
      })

      tabNavItem.on('click', function(e){
        e.preventDefault();
        var tabContent = $(this).data('tab');
        tabNavItem.removeClass('selected');
        $(this).addClass('selected');
        tabNavContent.slideUp();
        $this.find('[data-tab-content = '+  tabContent + ']').slideDown();
      })
    })
  }
  
  productDettIntroH()

  // Image Swap in product dett page
  if($('.image-swap').exists()){
    $('.image-swap').each(function(){
      var $this = $(this);
      var viewer = $this.find('.image-swap__viewer');
      var viewerSwitch = viewer.find('.over');
      var viewerSwitchImg = viewerSwitch.find('img');
      var viewerNav = $this.find('.image-swap__nav');
      var viewerNavItem = viewerNav.find('.item');

      viewerNavItem.each(function(){
        var $item = $(this);
        var imageSwitch = $item.data('image');
        
        $item.on({
          "mouseenter" : function(e){
            var _delay = (viewerSwitch.hasClass("show")) ? 800 : 0 ;
                                    
            if(imageSwitch != undefined) {
              viewerSwitchImg.on("load", function() {
                viewerSwitch.stop(true);
                viewerSwitch.delay(_delay).addClass('show');
              }).attr("src", imageSwitch);
            }
          },
          "mouseleave" : function(e){
            viewerSwitch.stop(true);
            viewerSwitch.delay(50).removeClass('show');
          },
        })
      })
    })
  }

  // Configuratore 3D
  // if ($('.product-configurator').exists()) {
  //   var productScrollElement = document.querySelector('.product-configurator');
  //   $('.product-configurator .scrolling-propagation').on('click', function(){
  //     $('html').css('overflow-y', 'unset');
  //     $('.product-configurator').addClass('active');
  //     disableBodyScroll(productScrollElement);
  //   });
  //   $('.product-configurator').on('mouseleave', function(){
  //     $('html').css('overflow-y', 'scroll');
  //     $('.product-configurator').removeClass('active');
  //     enableBodyScroll(productScrollElement);
  //   })
  // }

  if(document.querySelector('#productConfigurator')){
    let productConfiguratorElement = document.querySelector('#productConfigurator');
    let scrollingPropagation = productConfiguratorElement.querySelector('.scrolling-propagation');
    let iframeActive = 0;
    let cta = scrollingPropagation.querySelector('.cta');
    let productConfiguratorIframe = productConfiguratorElement.querySelector('#iframeConfigurator');
    
    cta.addEventListener('click', function(){
        document.querySelector('html').style.overflowY = 'unset';
        productConfiguratorElement.classList.add('active');
        disableBodyScroll(productConfiguratorElement);
        if(iframeActive == 0) {
          productConfiguratorElement.classList.add('loaded');
          productConfiguratorIframe.setAttribute("src", productConfiguratorIframe.getAttribute('data-src'));
          iframeActive = 1;
        }
    });

    productConfiguratorElement.addEventListener('mouseleave', function(){
        document.querySelector('html').style.overflowY = 'scroll';
        productConfiguratorElement.classList.remove('active');
        enableBodyScroll(productConfiguratorElement);
    });
  }
  
  // Timeline
  if($('.timeline').exists()){
    var inputs = $('.timeline .input');
    var paras = $('.timeline--description').find('.title');
    var currentInput = $('.timeline .input.active');

    timelineFillingUpdate(currentInput);

    timelineInterval = setInterval(function(){
      timelineAuto(inputs, paras)
    }, 3000);
    
    inputs.each(function(){
      var t = $(this);
      var ind = t.index();
      var matchedPara = paras.eq(ind);
      var dot = t.find('.dot');

      dot.on('click', function(){
        timelineFillingUpdate(t);

        t.add(matchedPara).addClass('active');
        inputs.not(t).add(paras.not(matchedPara)).removeClass('active');

        clearInterval(timelineInterval);

        timelineInterval = setInterval(function(){
          timelineAuto(inputs, paras)
        }, 3000);
      })
    });

  }

  // Contacts Map
  if ($(".map").exists() && $(".map #map").exists()) {
    var map;

    const GoogleMapsLoader = new Loader({
      apiKey: _gmapkey,
      version: "weekly",
      libraries: ["places"],
    });

    GoogleMapsLoader.load()
      .then(() => {
        map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: params.latitude, lng: params.longitude },
          zoom: 8,
          maxZoom: 18,
          streetViewControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
        });

        var image = {
          url: "" + _root + "img/marker.png",
          size: new google.maps.Size(130, 110),
        };
        var myMarker = new google.maps.Marker({
          position: { lat: params.latitude, lng: params.longitude },
          map: map,
          icon: image,
        });
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(myMarker.getPosition());
        map.fitBounds(bounds);
      })
      .catch((e) => {
        // do something
      });
  }

  // Form
  if ($(".thom-form").exists()) {
    var myForm = $(".thom-form");
    myForm.thomForm({
      errorMsg: myForm.find(".thom-form__error"),
      errorCallback: function(myTitle, myMessage) {
        if (myTitle) {
          myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          myForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        myForm.find(".thom-form__error").fadeIn(300);

        $('html,body').animate({
          scrollTop: myForm.offset().top - $('.navbar').height()
        }, 600);
      },
      myCallback: function(myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      }
    });
  }

  // add this code
  Flickity.prototype._createResizeClass = function () {
    this.element.classList.add("flickity-resize");
  };
  Flickity.createMethods.push("_createResizeClass");
  var FlickityResize = Flickity.prototype.resize;
  if ($(".flickity-resize").exists()) {
    Flickity.prototype.resize = function () {
      var $this = this;
      $(".flickity-resize").each(function () {
        $this.element.classList.remove("flickity-resize");
        FlickityResize.call($this);
        $this.element.classList.add("flickity-resize");
      });
    }
  }

  // Ajax modal
  $('a[rel="ajax:modal"]').off('click');
  $('a[rel="ajax:modal"]').on( 'click', function (event) {
    event.preventDefault();
    this.blur();

    $('.page-loader').addClass('show');

    $.ajax({
      url: $(this).attr("href"),
      success: function (newHTML, textStatus, jqXHR) {
        var myHtml = $(newHTML).find(".ajax-load");
        $(myHtml).appendTo("body");
        var newModal = $(myHtml).modal();
        newModal.on($.modal.OPEN, function(event, modal) {
          let simpleBar;
          if(deviceType() !== "mobile"){
            simpleBar = new SimpleBar(modal.$elm.find('.scroller')[0], { });
          }
          $('.page-loader').removeClass('show');
        }) 
      }
    });
    return false;
  });

  // Inline modal 
  if ($('.inline-modal[data-modal]').exists()){
    $('.inline-modal[data-modal]').off('click');
    $('.inline-modal[data-modal]').on('click', function (event) {  
      event.preventDefault();
      this.blur();
      var filesmodal = $($(this).data("modal"));
      filesmodal.modal();
    });
  }

  // scrollto
  $('[data-scrollto]').on('click', function(){
    var el = $(this).data('scrollto');
    $("html,body").animate(
      {
        scrollTop: $('#' + el).offset().top,
      },
      600
    );
  });

  $(window).on('resize', function() {
      rtime = new Date();
      if (timeout === false) {
          timeout = true;
          setTimeout(resizeend, delta);
      }
  });

  // update analytics
  if (typeof analyticsUpdate === "function") {
    analyticsUpdate();
  }
}

var rtime;
var timeout = false;
var delta = 200;

function resizeend() {
  if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
  } else {
      timeout = false;
      if($('body').hasClass('navbar--opened') && window.matchMedia("(min-width: 1200px)").matches){
        $('body').removeClass('navbar--opened')
      }
      productDettIntroH();
  }               
}

function productDettIntroH() {
  if($('.product-dett-intro').exists()){
    var h = $('.product-dett-intro .media').first().height();
    $('.product-dett-intro .text').css('min-height', h)
  }
}

function timelineFillingUpdate(t) {
  var fillingLine = document.querySelector('.filling-line');
  var left = t[0].offsetLeft;
  var width = t.width();

  left = Number(left) + (Number(width)/2);

  if(t.index() === $('.timeline .input').length - 1){
    left = "100%"
  }

  gsap.to(fillingLine, {
    width: left
  });
}

function timelineAuto(inputs, paras) {
  var s = inputs.length;
  var currentInput = $('.timeline .timeline--wrap .input.active');
  var ind = currentInput.index() + 1;
  if(ind >= s){
    ind = 0;
  }

  var next = inputs.eq(ind);
  var matchedPara = paras.eq(ind);

  timelineFillingUpdate(next);
  next.add(matchedPara).addClass('active');
  inputs.not(next).add(paras.not(matchedPara)).removeClass('active');

}

// BARBA JS
// start script
barba.use(barbaCss);

// define a global hook
barba.hooks.once((data) => {
  navbar();
  myapp();

  // Remove appended div to modal after close
  $(document).on($.modal.AFTER_CLOSE, function (event, modal) {
    $(".ajax-load.modal").remove();
  });

  // Analytics update
  $(document).on($.modal.OPEN, function (event, modal) {
    $(modal.$anchor).find(".close-modal").attr("data-barba-prevent", "self");
  });

  $(".page-transition").removeClass("show");
});


barba.hooks.enter((data) => {
  // console.log("enter");
  $.ajax({
    url: data.next.url.href,
    success: function (newHTML, textStatus, jqXHR) {
      // Meta update
      var headTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[itemprop]",
        "link[itemprop]",
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']",
      ].join(",");
      $("head").find(headTags).remove();
      $(newHTML).find(headTags).appendTo("head");

      // Menu Change
      var newMenu = $(newHTML).find(".navbar__collapse").html();
      $(".navbar .navbar__collapse").html(newMenu);

      // Preview Menu Change
      var newPreviewMenu = $(newHTML).find(".navbar__menu-preview").html();
      $(".navbar .navbar__menu-preview").html(newPreviewMenu);

      // Logo Menu Change
      var newNavSx = $(newHTML).find(".navbar__sx").html();
      $(".navbar .navbar__sx").html(newNavSx);

      // script variables
      // let js = data.next.container.querySelectorAll('main script');
      // console.log(js)
      // if(js != null){
      //     js.forEach((item) => {
      //         eval(item.innerHTML);
      //     });
      // }

      var js = $(newHTML).find("script");
      if (js != null) {
        $.globalEval(js.html());
      }
      
      myapp();
    },
    error: function (xhr, ajaxOptions, thrownError) {
      alert(xhr.status)
      alert(xhr.statusText)
      // alert(thrownError)
    },
    complete: function () {
    },
  });
});

barba.hooks.beforeLeave((data) => {
  // console.log("beforeLeave");
  // spinner().show();
  $(".page-transition").addClass("animIn");

  // document.querySelector('.js-navbar').classList.remove('on-stage', 'out-stage');
  // navbar().navbarAddScrollListener;
})

barba.hooks.leave((data) => {
  // this hook will be called for each transitions
  // console.log("leave 1");

  if(timelineInterval){
    clearInterval(timelineInterval);
  }

  $.modal.close();  
});

const theme = {
  value: window.matchMedia('(prefers-color-scheme: goldsmith)').matches ? 'goldsmith' : 'dental'
}
theme.value = theme.value === 'dental' ? 'goldsmith' : 'dental';

barba.hooks.beforeEnter((data) => {
  // console.log(data)

  theme.value = data.next.container.dataset.theme;
  document.firstElementChild.setAttribute('data-theme', theme.value);
  

  $(".wrapper").removeAttr("style");
  $(".main").removeAttr("style");

  $("body").removeClass("navbar--opened");
  
  $("html,body").animate({
    scrollTop: 0,
  }, 0);

  $(".navbar").removeClass("out-stage, on-stage--ico-menu, tween");
  
  ScrollTrigger.getAll().forEach(t => {
    t.kill()
  });

});

barba.hooks.afterEnter((data) => {

  // spinner().hide();
  $(".navbar").addClass("tween");
  $(".page-transition").removeClass("animIn");

  var hash = $(location).attr('hash');
  if(hash){
    $("html,body").animate({
        scrollTop: $(hash).offset().top,
      },400);
  }
});

// Basic default transition, with no rules and minimal hooks…
barba.init({
  timeout: 5000,
  cacheIgnore: true,
  prefetchIgnore: true,
  debug: false
});
